import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100 mb-5">
      <Container>
        <h2 className="main-heading text-center">Frequently Asked Questions</h2>
        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" eventKey="0">
                What is DeFi staking, and how does it work?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">DeFi staking is the process of locking crypto assets in the smart contract for liquidity providing and earning rewards as a return for staking the assets. When the user locks their assets in staking, the platform will utilize the assets for liquidity and offer rewards to the users. Liquidity is essential to maintain DeFi platforms' proper functioning, whereas DeFi staking platforms play a crucial role in liquidity providing.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle"  eventKey="1">
                What are the primary strategies utilized for computing interest or rewards in DeFi staking? 
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">When it comes to computing rewards for DeFi staking, the specific platform will decide the strategy for distributing rewards. For instance, some rewards are proportional to the total value of assets staked. Some rewards are based on the term of investment. So ultimately the DeFi staking platform will decide which strategy will work for their business idea.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle"  eventKey="2">
                What is the cost of building a DeFi staking platform? 
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">The cost of building a DeFi staking platform is approximately $10,000 to $20,000. The price estimation is not a fixed term and it includes the type of blockchain used, feature set, and tech stacks preferred. Depending upon the requirements, the cost may further increase.
                </div>
              </Accordion.Collapse>
            </div> 
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle"  eventKey="3">
                What is the time required to develop a DeFi staking platform?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">The time required to develop a DeFi staking platform would be around 7 to 14 days. However, in the case of add-on features and complex platform functionalities, the DeFi staking development time will vary. So get connected with our experts for an accurate schedule of DeFi staking development.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle"  eventKey="4">
                What are the popular cryptocurrencies used in DeFi staking?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">The DeFi staking platform supports multiple cryptocurrencies to extend staking services for various crypto assets. Among them, some popular cryptocurrencies have higher demand in the DeFi staking platform. They are,
                <ul>
                  <li>Bitcoin (BTC)</li>
                  <li>Binance Coin (BNB)</li>
                  <li>Ethereum (ETH)</li>
                  <li>Cardano (ADA)</li>
                  <li>Polkadot (DOT)</li>
                  <li>Solana (SOL)</li>
                  <li>Tezos (XTZ)</li>
                  <li>Tether (USDT)</li>
                  <li>USD Coin (USDC)</li>
                  <li>Cosmos (ATOM)</li>
                  <li>Avalanche (AVAX)</li>
                  <li>Dai (DAI)</li>
                </ul>
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion> 
      </Container>
    </section>
  )
}

export default FaqSection